import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { mainPageByUserType, routePaths } from 'projects/web/src/app/route-paths';

/**
 * Guard that checks user tier.
 *
 * Available tier should be in data.userTier field. If none userTypes specified, no users can enter.
 *
 * {
 *   path: 'users',
 *   canActivate: [RestrictUserTierGuard],
 *   data: {
 *     userTiers: [AccessTierLevel.Tier2, AccessTierLevel.Tier3],
 *   },.
 *   ...,
 * },.
 *
 * If in route data specified "permissionErrorMessage" key, this message will show if access denied.
 */
@Injectable({
	providedIn: 'root',
})
export class RestrictUserTierGuard {
	private readonly currentUserService = inject(CurrentUserService);

	private readonly notificationService = inject(NotificationService);

	private readonly router = inject(Router);

	private readonly route = inject(ActivatedRoute);

	/**
	 * Get user tiers.
	 *
	 * @param data Represents static data associated with a particular route.
	 */
	protected getUserTiers(data?: Data): AccessTierLevel[] {
		return data?.userTiers || [];
	}

	/**
	 * Determine if route could be achieved.
	 * @param route Activated route.
	 */
	public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		return this.commonRouteActivateOrLoadCheck(route);
	}

	/**
	 * Determine if route could be loaded.
	 * @param route Route.
	 */
	public canLoad(route: Route): Observable<boolean | UrlTree> {
		return this.commonRouteActivateOrLoadCheck(route);
	}

	/**
	 * Check that user accept terms.
	 *
	 * @param userTiers User available tiers.
	 * @param data Represents static data associated with a particular route.
	 */
	protected checkTiers(userTiers: AccessTierLevel[]): Observable<boolean | UrlTree> {
		return this.currentUserService.currentUser$.pipe(
			first(),
			map(user => {
				const userType = user?.userType;
				if (userType === UserType.Admin) {
					return true;
				}
				const userTier = user?.appUserData?.currentAccessTier?.tier;
				if (userTier && userTiers.includes(userTier)) {
					return true;
				}

				this.notificationService.showPrimary('You do not have access to this page.');

				// Access denied, return user to home page
				if (userType) {
					return this.router.createUrlTree(mainPageByUserType[userType]);
				}
				return this.router.createUrlTree(routePaths.login, {
					// eslint-disable-next-line rxjs/finnish
					queryParams: this.route.url ? { next: this.route.url } : undefined,
				});
			}),
		);
	}

	/**
	 * Common function to check for route activate or route load.
	 * @param route Activated Route or Route.
	 */
	private commonRouteActivateOrLoadCheck(
		route: ActivatedRouteSnapshot | Route,
	): Observable<boolean | UrlTree> {
		const tiers = this.getUserTiers(route.data);
		return this.checkTiers(tiers);
	}
}
